.header {
  display: flex;
  justify-content: space-between;
  margin: 2rem 8.4375rem;
}

.application-name {
  color: white;
  margin: 0;
  margin-left: 1rem;
  font-size: 1.25rem;
}

.logo-container {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.logo-container > img {
  height: 1.25rem;
}