.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
}

audio, canvas, video {
  display: inline-block;
}

html {
  font-size: 100%;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  background-color: #161616;
  font-family: Campton, Arial, sans-serif;
}

a:active, a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

p {
  color: white;
  margin: 0;
}

#root {
  max-width: 90rem;
  margin: 0 auto;
}

:root {
  --brand-red: #ff2211;
  --brand-red-darken: #88120a;
  --background-black: #161616;
  --background-grey: #1f1f1f;
  --background-light-grey: #313737;
  --border-color: #3d3d3d;
  --border-color-2: #2c2c2c;
  --scrollbar-width: 4px;
  --page-padding-on-sides: 8.375rem;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: var(--scrollbar-width); /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  z-index: 100;
  position: absolute;
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
  scrollbar-x-position: top;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: var(--brand-red-darken);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: var(--brand-red); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

/* INPUTS AUTOFILL */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px #161616 inset;
}

input:-webkit-autofill:active {
  /* Hack from http://stackoverflow.com/a/29350537 */
  transition: background-color 999999s ease-in-out 0s;
}
input:-webkit-autofill {
  /* Expose a hook for JavaScript when autofill is shown
      JavaScript can capture 'animationstart' events.
      Set minimal duration for Safari. */
  animation-name: onAutofillStart;
  animation-duration: 0.01s;
}
input:not(:-webkit-autofill) {
  /* Expose a hook for JS onAutofillCancel
      JavaScript can capture 'animationstart' events.
      Set minimal duration for Safari. */
  animation-name: onAutofillCancel;
  animation-duration: 0.01s;
}

/* Autofill detection workaround
   https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7 */
/* stylelint-disable block-no-empty */
@keyframes onAutofillStart {
  from {}
  to {}
}
@keyframes onAutofillCancel {
  from {}
  to {}
}

.default-button {
  width: 100%;
  height: 2rem;
  background-color: var(--brand-red);
  outline: 0;
  border: 0;
  color: white;
  cursor: pointer;
}

.rounded {
  border-radius: 1rem;
}

.default-button:hover {
  background-color: #d81d0e;
}

.default-button--grey {
  background-color: #292929;
}

.default-button--grey:hover {
  background-color: #353535;
}

.page {
  padding: 2.5rem 8.4375rem;
}

.page-header {
  color: var(--brand-red);
  font-size: 3.5rem;
  font-weight: bold;
}

.no-information {
  font-size: 3rem;
  font-weight: 200;
  color: var(--brand-red);
}

textarea {
  width: 100%;
  height: 16rem;
  position: relative;
  border: 2px solid #272c2c;
  font-size: .875rem;
  padding: calc(1rem + 1px) calc(1rem - 2px) !important; /* don't touch */
  outline: none;
  background-color: transparent;
  resize: none;
  color: white;
  line-height: 1.5rem;
}

.table-component {
  margin: 3rem auto 2rem auto;
  color: white;
  min-width: 18.75rem;
  text-align: left;
  overflow: hidden;
  width: 94%;
  display: table;
  padding: 0 0 8em 0;
}

.table-column {
  padding: 1.25rem;
  color: white;
}
.table-component__header {
   text-align: left;
   font-size: 1.75rem;
   color: var(--brand-red);
   box-shadow: 0 3px 3px -3px var(--brand-red);
 }

.table-component__row {
   text-align: left;
 }
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--brand-red);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.triangle_down {
  position: relative;
  top: -5px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.1em solid var(--brand-red);
  border-top: 0.1em solid var(--brand-red);
  transform: rotate(135deg);
  margin-right: 0.5em;
}
.triangle_up {
  position: relative;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.1em solid var(--brand-red);
  border-top: 0.1em solid var(--brand-red);
  transform: rotate(-45deg);
  margin-right: 0.5em;
}

.table-component__footer {
  color: var(--brand-red);
  box-shadow: 0 -3px 2px -3px var(--brand-red);
}

.flex-container {
  display: flex;
  align-items: center;
}